
// const baseurl = 'http://localhost:3005/'
const baseurl = 'https://dev-api.eisqr.com/'

export const API = {

    SERVICE_NOW_TOKEN_URL: baseurl + 'oauth-token', 
    SERVICE_NOW_BUG_URL: baseurl + 'reportBug',
    SERVICE_NOW_UPLOAD: baseurl + 'uploadToServiceNow',
    Login: baseurl + 'users/login',
    GetMe: baseurl + 'user-get-me',
    GetMeNew: baseurl + 'user-get-me-new',
    FilesUpload: baseurl + 'files',
    UserProfile: baseurl + 'user-profiles',
    UserProfile_Edit: (id) => { return baseurl + 'user-profiles/' + id },
    NewUserProfile: baseurl + 'new-user-profiles',
    ResetRequest: baseurl + 'reset-password/init',
    ResetPassword: baseurl + 'reset-password/finish',
    RemaindEsclate_Mail: baseurl + 'send-remainder',
    SubmissionMail: baseurl + 'send-email',
    Docs: baseurl + 'docs/',
    LocationOne_UP: (id) => { return baseurl + 'user-profiles/' + id + "/location-ones" },
    LocationTwo_UP: (id) => { return baseurl + 'location-ones/' + id + "/location-twos" },
    LocationThree_UP: (id) => { return baseurl + 'location-twos/' + id + "/location-threes" },
    LocationOne_Edit: (id) => { return baseurl + 'location-ones/' + id },
    LocationTwo_Edit: (id) => { return baseurl + 'location-twos/' + id },
    LocationThree_Edit: (id) => { return baseurl + 'location-threes/' + id },
    LocationThree: baseurl + 'location-threes',
    DCF: baseurl + 'form-collections',
    DCF_Title_Only : baseurl + 'form-collections?filter%5Bfields%5D%5Btitle%5D=true&filter%5Bfields%5D%5Bid%5D=true&filter%5Bfields%5D%5Btags%5D=true',
    CF: baseurl + 'consolidate-form-collections',
    DCF_Edit: (id) => { return baseurl + 'form-collections/' + id },
    CF_Edit: (id) => { return baseurl + 'consolidate-form-collections/' + id },
    Categories: baseurl + 'new-categories',
    Categories_Edit: (id) => { return baseurl + 'new-categories/' + id },
    DataPoint: baseurl + 'new-data-points',
    DataPoint_Edit: (id) => { return baseurl + 'new-data-points/' + id },
    Metric: baseurl + 'new-metrics',
    Metric_Edit: (id) => { return baseurl + 'new-metrics/' + id },
    Metric_Topic: (id) => { return baseurl + 'new-topics/' + id + '/new-metrics' },

    DataPoint_Metric: (id) => { return baseurl + 'new-metrics/' + id + '/new-data-points' },

    Topic: baseurl + 'new-topics',
    Topic_Edit: (id) => { return baseurl + 'new-topics/' + id },
    Topic_Categories: (id) => { return baseurl + 'new-categories/' + id + '/new-topics' },
    AssignDCFClient_Edit: (id) => { return baseurl + 'assign-dcf-clients/' + id },
    AssignDCFClient_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-clients' },
    Goal_UP: (id) => { return baseurl + 'user-profiles/' + id + '/new-goals' },
    Initiative_Goal: (id) => { return baseurl + 'new-goals/' + id + '/new-initiatives' },
    Target_Goal: (id) => { return baseurl + 'new-goals/' + id + '/new-targets' },
    Target_Goal_2: (id) => { return baseurl + 'new-goals/' + id + '/new-targets-twos' },
    Indicator_Target: (id) => { return baseurl + 'new-targets-twos/' + id + '/new-indicators' },
    Indicator_Goal: (id) => { return baseurl + 'new-goals/' + id + '/new-indicator-twos' },
    NewClientInitiative: baseurl + 'new-client-initiatives',
    NewClientInitiative_UP: (id) => { return baseurl + 'user-profiles/' + id + '/new-client-initiatives' },
    NewClientInitiative_Edit: (id) => { return baseurl + 'new-client-initiatives/' + id },
    Goal_Edit: (id) => { return baseurl + 'new-goals/' + id },
    Target_Edit: (id) => { return baseurl + 'new-targets/' + id },
    Target_Two_Edit: (id) => { return baseurl + 'new-targets-twos/' + id },
    Indicator_Edit: (id) => { return baseurl + 'new-indicator-twos/' + id },
    Initiative_Edit: (id) => { return baseurl + 'new-initiatives/' + id },
    AssignDCFUser_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-users' },
    DCF_User_Edit: (id) => { return baseurl + 'assign-dcf-users/' + id },
    AssignDCFSupplier_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-suppliers' },
    DCF_Supplier_Edit: (id) => { return baseurl + 'assign-dcf-suppliers/' + id },
    DCF_Submission_Edit: (id) => { return baseurl + 'submit-dcfs/' + id },
    DCF_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/submit-dcfs' },
    DP_report_UP: (id) => { return baseurl + 'user-profiles/' + id + '/dp-reports' },
    STD_Country: baseurl + 'std-countries',
    STD_Country_Edit: (id) => { return baseurl + 'std-countries/' + id },
    STD_Year_Country: (id) => { return baseurl + 'std-countries/' + id + '/std-years' },
    STD_Year_Edit: (id) => { return baseurl + 'std-years/' + id },
    STD_Name_Year: (id) => { return baseurl + 'std-years/' + id + '/std-names' },
    STD_Name_Edit: (id) => { return baseurl + 'std-names/' + id },
    STD_Scope_Name: (id) => { return baseurl + 'std-names/' + id + '/std-scopes' },
    STD_Scope_Edit: (id) => { return baseurl + 'std-scopes/' + id },
    STD_Topic_Scope: (id) => { return baseurl + 'std-scopes/' + id + '/std-topics' },
    STD_Topic_Edit: (id) => { return baseurl + 'std-topics/' + id },
    Report_Name_Ones: baseurl + 'report-name-ones',
    Report_Name_Twos: baseurl + 'report-name-twos',
    Report_Name_Ones_Edit: (id) => { return baseurl + 'report-name-ones/' + id },
    Report_Name_Twos_RNO: (id) => { return baseurl + 'report-name-ones/' + id + '/report-name-twos' },
    Report_Name_Twos_Edit: (id) => { return baseurl + 'report-name-twos/' + id },
    RF: baseurl + 'response-form-collections',
    RF_Edit: (id) => { return baseurl + 'response-form-collections/' + id },
    RF_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/submit-rfs' },
    QL_Listing_Filter_UP: (id) => { return baseurl + 'user-profiles/' + id + '/ql-listing-filters' },
    QL_Listing_Filter_Edit: (id) => { return baseurl + 'ql-listing-filters/' + id },
    RF_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-rf-users' },
    RF_User_Edit: (id) => { return baseurl + 'assign-rf-users/' + id },
    EF_Date_Std: (id) => { return baseurl + 'new-ef-stds/' + id + '/new-ef-dates' },
    EF_Category_Std: (id) => { return baseurl + 'new-ef-stds/' + id + '/new-ef-categories' },
    EF_Std: baseurl + 'new-ef-stds',
    EF_SC4: baseurl + 'new-ef-subcategory4s',
    EF_SC3: baseurl + 'new-ef-subcategory3s',
    EF_SC2: baseurl + 'new-ef-subcategory2s',
    EF_SC1: baseurl + 'new-ef-subcategory1s',

    EF_SC1_Edit: (id) => { return baseurl + 'new-ef-subcategory1s/' + id },
    EF_SC2_Edit: (id) => { return baseurl + 'new-ef-subcategory2s/' + id },
    EF_SC3_Edit: (id) => { return baseurl + 'new-ef-subcategory3s/' + id },
    EF_SC4_Edit: (id) => { return baseurl + 'new-ef-subcategory4s/' + id },
    EF_SC1_Cat: (id) => { return baseurl + 'new-ef-categories/' + id + '/new-ef-subcategory1s' },
    EF_SC2_Sc1: (id) => { return baseurl + 'new-ef-subcategory1s/' + id + '/new-ef-subcategory2s' },
    EF_SC3_Sc2: (id) => { return baseurl + 'new-ef-subcategory2s/' + id + '/new-ef-subcategory3s' },
    EF_SC4_Sc3: (id) => { return baseurl + 'new-ef-subcategory3s/' + id + '/new-ef-subcategory4s' },

    EF_Std_Edit: (id) => { return baseurl + 'new-ef-stds/' + id },
    EF_Date_Edit: (id) => { return baseurl + 'new-ef-dates/' + id },
    NEW_EF_Date: (id) => { return baseurl + 'new-ef-dates/' + id + '/new-efs' },
    NEW_EF_Edit: (id) => { return baseurl + 'new-efs/' + id },
    EF_Item_NEW_EF: (id) => { return baseurl + 'new-efs/' + id + '/new-ef-items' },
    EF_Item_Edit: (id) => { return baseurl + 'new-ef-items/' + id },
    EF_Category: baseurl + 'new-ef-categories',
    EF_Category_edit: (id) => { return baseurl + 'new-ef-categories/' + id },
    EF_Item_Import: baseurl + 'new-ef-items/multiple',
    DCF_Recent_Submission: baseurl + 'get-recent-dcf-submission',
    STT_India_User: baseurl + 'india/users',
    STT_Singapore_User: baseurl + 'internal/users',
    STT_User_Role: baseurl + 'ad-users',
    STT_Get_User_Role: baseurl + "ad-users-by-mail",

    DF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-df-entities' },
    DF_Entity_Edit: (id) => { return baseurl + 'assign-df-entities/' + id },
    DF_Entity_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-df-entity-users' },
    DF_Entity_User_Edit: (id) => { return 'assign-df-entity-users/' + id },

    DCF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-entities' },
    DCF_Entity_Edit: (id) => { return 'assign-dcf-entities/' + id },
    DCF_Entity_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-dcf-entity-users' },
    DCF_Entity_User_Edit: (id) => { return 'assign-dcf-entity-users/' + id },

    SRF_Entity_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-srf-entities' },
    SRF_Entity_Edit: (id) => { return baseurl + 'assign-srf-entities/' + id },
    SRF_Entity_User_UP: (id) => { return baseurl + 'user-profiles/' + id + '/assign-srf-entity-users' },
    SRF_Entity_User_Edit: (id) => { return 'assign-srf-entity-users/' + id },

    QN_Submission_Edit: (id) => { return baseurl + 'quantitative-submissions/' + id },
    QN_Submit_UP: (id) => { return baseurl + 'user-profiles/' + id + '/quantitative-submissions' },
    Client_EF_Cat_Ass_Up: (id) => { return baseurl + 'user-profiles/' + id + '/client-ef-category-assignments' },
    NewsCirculate_UP: (id) => { return baseurl + 'user-profiles/' + id + '/news-circulations' },
    Multiple_QN_Submission_UP: (id) => { return baseurl + 'user-profiles/' + id + '/multiple-import-qn-submissions' },
    Client_Certification: baseurl + 'new-client-certifications',
    Client_Certification_UP: (id) => { return baseurl + 'user-profiles/' + id + '/new-client-certifications' },
    Client_Certification_Edit: (id) => { return baseurl + 'new-client-certifications/' + id },
    Certifcation_UP: (id) => { return baseurl + 'user-profiles/' + id + '/certifications' },
    GetRoleByUserId:(id1,id2)=>{ return  baseurl +`user-role-authorizations?filter%5Bwhere%5D%5BuserProfileId%5D=${id1}&filter%5Bwhere%5D%5Buser_id%5D=${id2}` },
    Certifcation: baseurl + 'certifications',
    QNDP_Report_UP: (id) => { return baseurl + 'user-profiles/' + id + '/quantitative-dp-reports' },
    QNIndicatorApprove_UP: (id) => { return baseurl + 'user-profiles/' + id + '/qn-indicator-approvals' },
    QNIndicatorApprove_Edit: (id) => { return baseurl + '/qn-indicator-approvals/'+id },
    GetRole_Up: (id)=>{ return  baseurl +`user-role-authorizations?filter%5Bwhere%5D%5BuserProfileId%5D=${id}` },
    NewClientInitiativeUserAss_UP: (id) => { return baseurl + 'user-profiles/' + id + '/new-client-initiative-user-assignment' },
PastDataSubmission:baseurl + 'quantitative-submissions-initiative-past'
    



}
export { baseurl }