import { DateTime } from "luxon";
import { getLocationDetailsByLevelLocationId, getRPTextFormat } from "./helper";




const calculateEmissionByDCF = (
  standardData,
  submission,
  standardId,
  categoryId,
  findingKeys,
  valueKey,
  subCategorykeys,
  subcategory,
  locationData,
  timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
) => {
  let structuredData = [], emissionData = []

  // Step 1: Validate if submission.response exists
  if (!submission || !submission.response || !Array.isArray(submission.response)) {
    submission.error = "No valid response property found in submission.";
    return submission;
  }

  // Step 2: Get the latest reporting period from the submission response
  const reportingPeriod = submission?.reporting_period?.[0];
  if (!reportingPeriod) {
    submission.error = "No reporting_period found in submission response.";
    return submission;
  }

  // Step 3: Parse the reporting period date (MM-yyyy format) and add 1 month
  let lastDate;
  try {
    lastDate = DateTime.fromFormat(reportingPeriod, "MM-yyyy", { zone: "utc" })
      .setZone(timezone)
      .plus({ months: 1 });
  } catch (error) {
    submission.error = `Invalid reporting_period format: ${reportingPeriod}`;
    return submission;
  }

  // Step 4: Format the next month for comparison
  const reportingDate = lastDate.toFormat("MM-yyyy");

  // Step 5: Iterate through standardData and find matching standardId
  const filteredData = standardData.filter((standard) => {
    // Match by standardId
    if (standard.id !== standardId) return false;

    // Step 6: Validate if newEfDates is available
    if (!standard.newEfDates || standard.newEfDates.length === 0) {
      submission.error = "newEfDates is empty or missing in standard.";
      return false;
    }

    // Step 7: Check the date ranges in newEfDates and find the index for matching range
    const dateIndex = standard.newEfDates.findIndex((dateRange) => {
      const startDate = DateTime.fromISO(dateRange.start, { zone: "utc" }).setZone(timezone);
      const endDate = dateRange.end
        ? DateTime.fromISO(dateRange.end, { zone: "utc" }).setZone(timezone)
        : DateTime.local();

      return lastDate >= startDate && lastDate <= endDate;
    });

    if (dateIndex === -1) {
      submission.error = "No matching date range found in newEfDates.";
      return false;
    }

    const newEfDates = standard.newEfDates[dateIndex];

    if (!newEfDates.newEfs || newEfDates.newEfs.length === 0) {
      submission.error = "newEf is empty or missing in standard.";
      return false;
    }

    const efCatIndex = newEfDates.newEfs.findIndex((x) => x.category === categoryId);
    if (efCatIndex === -1) {
      submission.error = `No match found for categoryId: ${categoryId} in newEfs.`;
      return false;
    }

    const newEfItems = newEfDates.newEfs[efCatIndex]?.newEfItems || [];
    if (newEfItems.length === 0) {
      submission.error = "newEfItems is empty or missing in newEfs.";
      return false;
    }
    // Iterate over submission.response and check for matches in newEfItems
    submission.response.forEach((resItem) => {
      const matchedItem = newEfItems.find((item) => {
        return findingKeys.every((key, index) => {
          const subCategoryKey = item[`subcategory${subCategorykeys[index]}`];
          return resItem[key] === subCategoryKey; // Check if key matches corresponding subcategory value
        });
      });

      if (matchedItem) {
        resItem.emission = (resItem[valueKey] * (matchedItem.co2e / 1000));
        resItem.ef = matchedItem; // Attach matching newEfItem as 'ef'


        if (submission.dcfId === 304) {
          emissionData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),
            "Parameter": matchedItem.subcat2.title,
            UoM: matchedItem.subcat3.title,
            "Quantity Used": resItem.DPA0336, 'Value': matchedItem.co2e, "Unit": "kgCO2e", "Emission Factor": standardData.find((standard) => { return standard.id === standardId })?.title, "Source": newEfDates.newEfs[efCatIndex].source, "Start Date": DateTime.fromISO(standard.newEfDates[dateIndex].start, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy'), "End Date": standard.newEfDates[dateIndex].end ? DateTime.fromISO(standard.newEfDates[dateIndex].end, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy') : "Ongoing"

          });

          structuredData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),

            "Parameter": matchedItem.subcat2.title,
            UoM: matchedItem.subcat3.title,
            "Value": resItem.DPA0336,
          });
        } else if (submission.dcfId === 305) {
          console.log(resItem.DPA0138)
          emissionData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ), "Type of AC/ Chiller / freezer used": resItem.DPA0135,
            "Parameter": matchedItem.subcat1.title,
            "Gas Refilled": resItem.DPA0138, 'Value': matchedItem.co2e, "Unit": "kgCO2e", "Emission Factor": standardData.find((standard) => { return standard.id === standardId })?.title, "Source": newEfDates.newEfs[efCatIndex].source, "Start Date": DateTime.fromISO(standard.newEfDates[dateIndex].start, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy'), "End Date": standard.newEfDates[dateIndex].end ? DateTime.fromISO(standard.newEfDates[dateIndex].end, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy') : "Ongoing"

          });
          structuredData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),
            "Parameter": matchedItem.subcat1.title,
            "Value": resItem.DPA0138,
          });
        }

      } else {

        if (submission.dcfId === 304) {
          emissionData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),
            "Parameter": subcategory.one.find((x) => x.id === resItem.DPA0131)?.title,
            UoM: subcategory.one.find((x) => x.id === resItem.DPA0132)?.title,
            "Quantity Used": resItem.DPA0336, 'Value': 'NA', "Unit": "kgCO2e", "Emission Factor": standardData.find((standard) => { return standard.id === standardId })?.title, "Source": newEfDates.newEfs[efCatIndex].source, "Start Date": DateTime.fromISO(standard.newEfDates[dateIndex].start, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy'), "End Date": standard.newEfDates[dateIndex].end ? DateTime.fromISO(standard.newEfDates[dateIndex].end, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy') : "Ongoing"

          });
          structuredData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),

            "Parameter": subcategory.one.find((x) => x.id === resItem.DPA0131)?.title,
            UoM: subcategory.one.find((x) => x.id === resItem.DPA0132)?.title,
            "Value": resItem.DPA0336,
          });
        } else if (submission.dcfId === 305) {
          emissionData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),
            "Parameter": 'Gas Type Not Found',
            "Gas Refilled": resItem.DPA0138, 'Value': 'NA', "Unit": "kgCO2e", "Emission Factor": standardData.find((standard) => { return standard.id === standardId })?.title, "Source": newEfDates.newEfs[efCatIndex].source, "Start Date": DateTime.fromISO(standard.newEfDates[dateIndex].start, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy'), "End Date": standard.newEfDates[dateIndex].end ? DateTime.fromISO(standard.newEfDates[dateIndex].end, { zone: "Asia/Calcutta" }).toFormat('MMM-yyyy') : "Ongoing"

          });
          structuredData.push({
            "Reporting Period": getRPTextFormat(submission.reporting_period),
            "Form": submission?.dcf?.title || 'Not Found',
            ...getLocationDetailsByLevelLocationId(
              submission.level,
              submission.locationId,
              locationData
            ),

            "Parameter": 'Gas Type Not Found',
            "Value": resItem.DPA0138,
          });
        }
      }
    });

    return true;
  });

  // Step 11: Check if no match was found, and add an error to submission
  if (structuredData.length === 0) {
    submission.error = "No matching newEfItem found or invalid data.";
    return submission;
  }

  // Step 12: Attach the filtered data to the submission object
  submission.calculatedResponse = filteredData;
  submission.structuredData = structuredData;
  submission.emissionData = emissionData;

  return submission;
};
function waterWithdraw285(submission, locationData) {
  const DPAN0048Options = [
    { name: "Self Generated Water", id: 6 },
    { name: "Third-Party Water", id: 5 },
  ];
  const DPAN1158Options = [
    { name: "Groundwater Wells Operated", id: 1 },
    { name: "Effluent/ Sewage Treatment Recycle", id: 2 },
    { name: "Rainwater Harvesting", id: 3 },
    { name: "Others", id: 99 },
  ];
  const DPAN1200Options = [
    { name: "Surface water", id: 1 },
    { name: "Ground Water", id: 2 },
    { name: "Sea Water", id: 3 },
    { name: "Imported Water from Industrial District", id: 8 },
    { name: "Unknown", id: 9 },
  ];
  const DPAN0050Options = [
    { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
    { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
  ];
  // Collation object to track summarized data
  const collatedData = {};

  submission.response.forEach((submission) => {
    const waterSourceOption = DPAN0048Options.find(
      (option) => option.id === submission.DPAN0048
    );
    if (!waterSourceOption) return; // Skip invalid data

    const waterSource = waterSourceOption.name;

    const methodOfGeneration =
      submission.DPAN0048 === 6
        ? DPAN1158Options.find((option) => option.id === submission.DPAN1158)?.name
        : DPAN1200Options.find((option) => option.id === submission.DPAN1200)?.name;

    const quantityOfWater =
      submission.DPAN0048 === 5
        ? submission.DPAN1161
        : submission.DPAN1159;
    const tds =
      DPAN0050Options.find((option) => option.id === submission.DPAN0050)?.name || '';

    if (!methodOfGeneration || quantityOfWater == null) return; // Skip invalid entries

    const key = `${waterSource}|${methodOfGeneration}|${tds}`;
    if (!collatedData[key]) {
      collatedData[key] = {
        "Water Source": waterSource,
        "Method of Generation": methodOfGeneration,
        "Total Dissolved Solids of Water Withdrawal": tds,
        "Quantity of Water Generated (m3)": 0,
      };
    }

    collatedData[key]["Quantity of Water Generated (m3)"] += quantityOfWater;
  });

  // Convert collated data to array format
  return Object.values(collatedData).map(i => ({
    ...i, "Form": submission?.dcf?.title || 'Not Found', "Reporting Period": getRPTextFormat(submission.reporting_period), ...getLocationDetailsByLevelLocationId(
      submission.level,
      submission.locationId,
      locationData
    )
  }))
}
function WaterDisposal286(submission, locationData) {
  const DPAN0052Options = [
    { name: "Surface Water", id: 1 },
    { name: "Ground Water", id: 2 },
    { name: "Sea Water", id: 3 },
    { name: "Third-Party Water (E.g.- Industrial Sewer)", id: 5 },
    { name: "Water Reuse", id: 6 },
    { name: "Water Recycled", id: 7 },
  ];

  const DPAN0054Options = [
    { name: "(≤1,000 mg/L Total Dissolved Solids)", id: 1 },
    { name: "(>1,000 mg/L Total Dissolved Solids)", id: 2 },
  ];

  // Collation object to store summarized data
  const collatedData = {};

  submission.response.forEach((submission) => {
    const destinationOption = DPAN0052Options.find(
      (option) => option.id === submission.DPAN0052
    );
    if (!destinationOption) return; // Skip invalid data

    const destination = destinationOption.name;
    const tdsOption = DPAN0054Options.find(
      (option) => option.id === submission.DPAN0054
    );
    const tds = tdsOption?.name || '';


    const quantityOfDischarge = submission.DPAN0053; // Assuming DPAN0053 holds the quantity

    if (quantityOfDischarge === null && quantityOfDischarge === undefined) return; // Skip invalid entries

    const key = `${destination}|${tds}`;
    if (!collatedData[key]) {
      collatedData[key] = {
        "Destination of Water Discharge": destination,
        "Total Dissolved Solids of the Destination of Disposal": tds,

        "Quantity of Water Discharge (m3)": quantityOfDischarge,
      };
    }

    collatedData[key]["Quantity of Water Discharge (m3)"] += quantityOfDischarge;
  });

  // Convert collated data to array format
  return Object.values(collatedData).map(i => ({
    ...i, "Form": submission?.dcf?.title || 'Not Found', "Reporting Period": getRPTextFormat(submission.reporting_period), ...getLocationDetailsByLevelLocationId(
      submission.level,
      submission.locationId,
      locationData
    )
  }))
}
function hazardousWaste297(submission, locationData) {
  const DPAH0013Options = [
    { name: 'Used Engine Oil', id: 1 },
    { name: 'Used Lubricating Oil', id: 2 },
    { name: 'Used Oil Filters', id: 3 },
    { name: 'Used Coolant', id: 4 },
    { name: 'Contaminated PPE', id: 5 },
    { name: 'Others - Please include in remarks', id: 6 },
  ];

  const DPAH0014Options = [
    { name: 'kg', id: 1 },
    { name: 'litre', id: 2 },
    { name: 'number', id: 3 },
  ];

  const DPAH0017Options = [
    { name: 'Incineration (w/o Energy Recovery)', id: 1 },
    { name: 'Incineration (with Energy Recovery)', id: 2 },
    { name: 'Landfill', id: 3 },
    { name: 'Recycle', id: 4 },
    { name: 'Composting', id: 5 },
    { name: 'Waste Recovery', id: 6 },
    { name: 'Unknown', id: 7 },
    { name: 'Others - Please include in remarks', id: 8 },
  ];

  const unitConversionToMT = {
    1: 0.001, // kg to MT
    2: 0.00085, // litre to MT
    3: 0.00001, // number to MT (arbitrary value for example)
  };

  let mappedData = submission.response.map((item) => {
    const category = DPAH0013Options.find((option) => option.id === item.DPAH0013)?.name || 'Unknown';
    const unit = DPAH0014Options.find((option) => option.id === item.DPAH0014)?.name || 'Unknown';
    const disposalMethod = DPAH0017Options.find((option) => option.id === item.DPAH0017)?.name || 'Unknown';
    const quantity = item.DPAH0015 || 0;
    const conversionFactor = unitConversionToMT[item.DPAH0014] || 1;
    const quantity_converted = quantity * conversionFactor;

    return {
      "Reporting Period": getRPTextFormat(submission.reporting_period),
      "Form": submission?.dcf?.title || 'Not Found',
      ...getLocationDetailsByLevelLocationId(
        submission.level,
        submission.locationId,
        locationData
      ),
      Parameter: category,
      UoM: unit,
      "Additional Parameter 1": disposalMethod,
      Value: quantity,
      quantity_converted: parseFloat(quantity_converted.toFixed(4))
    };
  });
  submission.structuredData = mappedData;

  return submission

}
function nonHazardousWaste307(submission, locationData) {
  const DPAH500Options = [
    { name: "Paper - all types", id: 1 },
    { name: "Plastic - all types", id: 2 },
    { name: "Metals - all types", id: 3 },
    { name: "Glass - all types", id: 11 },
    { name: "Wood - all types", id: 13 },
    { name: "Electrical items - WEEE - (ex. printers)", id: 4 },
    { name: "Electrical items - Batteries", id: 5 },
    { name: "General Waste - Mixed Commercial and Industrial Waste", id: 6 },
    { name: "General Waste - Organic: mixed food and garden waste", id: 7 },
    { name: "General Waste - Organic: garden waste", id: 8 },
    { name: "General Waste - Organic: food and drink waste", id: 9 },
    { name: "General Waste - Household residual waste", id: 10 },
    { name: "Others - Please include in remarks", id: 12 },
  ];

  const DPAH501Options = [
    { name: "kg", id: 1 },
    { name: "litre", id: 2 },
    { name: "number", id: 3 },
  ];

  const DPAH504Options = [
    { name: "Incineration (w/o Energy Recovery)", id: 1 },
    { name: "Incineration (with Energy Recovery)", id: 2 },
    { name: "Landfill", id: 3 },
    { name: "Recycle", id: 4 },
    { name: "Composting", id: 5 },
    { name: "Waste Recovery", id: 6 },
    { name: "Unknown", id: 7 },
    { name: "Others - Please include in remarks", id: 8 },
  ];

  const unitConversionToMT = {
    1: 0.001, // kg to MT
    2: 0.00085, // litre to MT
    3: 0.00001, // number to MT (arbitrary value for example)
  };

  let mappedData = submission.response.map((item) => {
    const category = DPAH500Options.find((option) => option.id === item.DPAH500)?.name || "Unknown";
    const unit = DPAH501Options.find((option) => option.id === item.DPAH501)?.name || "Unknown";
    const disposalMethod = DPAH504Options.find((option) => option.id === item.DPAH504)?.name || "Unknown";
    const quantity = item.DPAH502 || 0;
    const conversionFactor = unitConversionToMT[item.DPAH501] || 1;
    const quantity_converted = quantity * conversionFactor;

    return {
      "Reporting Period": getRPTextFormat(submission.reporting_period),
      "Form": submission?.dcf?.title || "Not Found",
      ...getLocationDetailsByLevelLocationId(
        submission.level,
        submission.locationId,
        locationData
      ),
      Parameter: category,
      UoM: unit,
      "Additional Parameter 1": disposalMethod,
      Value: quantity,
      quantity_converted: parseFloat(quantity_converted.toFixed(4)),
    };
  });

  submission.structuredData = mappedData;

  return submission;
}
const getSingleValueByDp = (data, key) => {
  console.log(data)
  if (data.response && data.response.length) {
    data.emission = 0
    for (const item of data.response) {
      data.emission += item[key]
    }
    return data
  } else {
    data.emission = 0
    return data
  }

}

export { calculateEmissionByDCF, waterWithdraw285, WaterDisposal286, getSingleValueByDp, hazardousWaste297,nonHazardousWaste307 }
